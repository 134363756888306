import {
    Image,
    Text,
    Center,
    HStack,
    Heading,
    Stack,
    Input,
    InputGroup,
    InputLeftElement,
    FormControl,
    FormErrorMessage,
    Button,
    Checkbox,
    Link as ChakraLink,
    LinkBox,
    LinkOverlay,
    Icon,
    Radio,
    RadioGroup
} from '@chakra-ui/react';
import { FaCog } from 'react-icons/fa';
import { RiLockPasswordFill } from 'react-icons/ri';
import Logo from '../../assets/img/logo.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { firebaseFirestore, firebaseFunctions } from '../../firebase';
import { httpsCallable } from '@firebase/functions';
import { AlertModal } from '../../components/AlertModal';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';

const toggleSalesCallable = httpsCallable<any, any>(firebaseFunctions, 'toggleSales');

type AdminFormData = {
    secret: string;
}

export const Admin = () => {
    const [salesConfig, setSalesConfig] = useState('enabled');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [miscSettingsSnapshot] = useDocumentData(doc(
        firebaseFirestore,
        'settings',
        'misc',
    ));

    useEffect(() => {
        if (miscSettingsSnapshot?.canSell) {
            setSalesConfig('enabled');
        } else {
            setSalesConfig('disabled');
        }
    }, [miscSettingsSnapshot]);

    const send = (data: AdminFormData) => {
        return new Promise<void>((resolve) => {
            toggleSalesCallable({
                secret: data.secret,
                canSell: salesConfig === 'enabled'
            }).then(() => {
                reset();
                setHasSuccess(true);
                resolve();
            }).catch(e => {
                reset();
                setHasError(true);
                console.error(e);
                resolve();
            });
        });
    }

    const {
        reset,
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<AdminFormData>();

    return (
        <>
            <AlertModal
                status={'success'}
                title={'¡Éxito!'}
                message={'Configuración de ventas actualizada correctamente.'}
                isOpen={hasSuccess}
                onClose={() => {
                    setHasSuccess(false);
                }} />
            <AlertModal
                status={'error'}
                title={'Ha ocurrido un error'}
                message={'Actualmente no podemos cambiar el estado del proceso de venta. Inténtalo de nuevo más tarde.'}
                isOpen={hasError}
                onClose={() => {
                    setHasError(false);
                }} />
            <Stack spacing={12} as='form' noValidate onSubmit={handleSubmit(send)}>
                <Stack>
                    <Center>
                        <LinkBox>
                            <LinkOverlay as={Link} to='/'>
                                <Image sx={{ width: 300 }} src={Logo} />
                            </LinkOverlay>
                        </LinkBox>
                    </Center>
                    <HStack>
                        <Icon as={FaCog} boxSize={6} />
                        <Heading fontWeight={500} fontSize={'md'}>Configuración</Heading>
                    </HStack>
                    <Text fontSize={'sm'} fontWeight={400}>
                        Esta pantalla permite deshabilitar o habilitar el proceso de ventas. Introduce la contraseña, selecciona si quieres habilitar o deshabilitar el proceso y pulsa en el botón de Actualizar.
                    </Text>
                </Stack>
                <Stack spacing={6}>
                    <RadioGroup onChange={setSalesConfig} value={salesConfig}>
                        <Stack spacing='4'>
                            <Radio size='lg' value='enabled' colorScheme='brand'>
                                Ventas habilitadas
                            </Radio>
                            <Radio size='lg' value='disabled' colorScheme='brand'>
                                Ventas deshabilitadas
                            </Radio>
                        </Stack>
                    </RadioGroup>
                    <FormControl isInvalid={errors.secret !== undefined}>
                        <InputGroup size='lg'>
                            <InputLeftElement pointerEvents='none'>
                                <Icon as={RiLockPasswordFill} color='#1E3789' boxSize={6} />
                            </InputLeftElement>
                            <Input
                                fontWeight={600}
                                color='#1E3789'
                                type='password'
                                placeholder='Contraseña'
                                {...register('secret', {
                                    required: 'Campo obligatorio.',
                                })}
                            />
                        </InputGroup>
                        {errors.secret &&
                            <FormErrorMessage color='#ffe41b'>
                                {errors.secret.message}
                            </FormErrorMessage>
                        }
                    </FormControl>
                </Stack>
                <Stack spacing={4}>
                    <Center px={8}>
                        <Button
                            type='submit'
                            isLoading={isSubmitting}
                            width='full'
                            size='xl'
                            borderRadius={'20px'}
                            variant='solid'
                            colorScheme='brand'
                            color={'#1E3689'}>
                            Actualizar
                        </Button>
                    </Center>
                </Stack>
            </Stack>
        </>
    )
}