import {
    Image,
    Text,
    Center,
    Stack,
    Button,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react';
import Logo from '../../assets/img/logo.png';
import { ArrowBackIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

export const PaymentOk = () => {

    return (
        <Stack spacing={12}>
            <Stack>
                <Center>
                    <LinkBox>
                        <LinkOverlay as={Link} to='/'>
                            <Image sx={{ width: 300 }} src={Logo} />
                        </LinkOverlay>
                    </LinkBox>
                </Center>
            </Stack>
            <Stack pt={8} spacing={4}>
                <Center>
                    <CheckCircleIcon boxSize={16} color='#FFC500' />
                </Center>
                <Text fontWeight={500} align={'center'} fontSize={'2xl'}>¡Pago realizado correctamente!</Text>
                <Text align={'justify'}>Hemos recibido el pago y te hemos enviado la confirmación de compra por correo electrónico. Si no encuentras el correo asegúrate de revisar el buzón de correo no deseado.</Text>
            </Stack>
            <Stack spacing={4}>
                <Center px={8}>
                    <Button
                        variant={'outline'}
                        backgroundColor={'transparent'}
                        colorScheme='brand'
                        as={Link}
                        to={`/hammocks`}
                        width='full'
                        size='xl'
                        borderRadius={'20px'}
                        color='white'
                        borderColor={'yellow'}
                        borderWidth={2}
                        leftIcon={<ArrowBackIcon boxSize={6} />}
                        _hover={{
                            borderWidth: 3
                        }}
                        _active={{
                            borderWidth: 3
                        }}>
                        Volver al inicio
                    </Button>
                </Center>
            </Stack>
        </Stack>
    )
}