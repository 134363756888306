import { extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'

import '@fontsource/montserrat/latin-700.css'
import '@fontsource/montserrat/latin-600.css'
import '@fontsource/montserrat/latin-500.css'
import '@fontsource/montserrat/latin-400.css'

export const theme = extendTheme(
    proTheme,
    {
        fonts: {
            heading: `'Montserrat', sans-serif`,
            body: `'Montserrat', sans-serif`,
        },
        styles: {
            global: {
                '#root': {
                    height: '100%',
                },
                '.no-scroll-bar': {
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                },
                '.no-scroll-bar::-webkit-scrollbar': {
                    display: 'none'
                },
            },
        },
        config: {
            initialColorMode: 'light',
            useSystemColorMode: false
        },
        colors: {
            ...proTheme.colors,
            brand: {
                50: '#ffffea',
                100: '#fffcc5',
                200: '#fffa85',
                300: '#fff146',
                400: '#ffe41b',
                500: '#ffc502',
                600: '#e29800',
                700: '#bb6c02',
                800: '#985408',
                900: '#7c440b',
                950: '#482300'
            },
            brandSecondary: {
                50: '#eff5ff',
                100: '#dbe9fe',
                200: '#bfd9fe',
                300: '#93c1fd',
                400: '#60a0fa',
                500: '#3b7bf6',
                600: '#255ceb',
                700: '#1d47d8',
                800: '#1e3baf',
                900: '#1e3689',
                950: '#172354',
            },
        },
        semanticTokens: {
            colors: {
                'bg-accent': 'brandSecondary.900',
                'bg-accent-subtle': 'brandSecondary.800',
            }
        },
        components: {
            Checkbox: {
                baseStyle: {
                    label: {
                        color: 'white'
                    }
                }
            },
        }
    }
);