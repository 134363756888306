import { Container, Stack, List, ListItem, ListIcon, Box, Link } from '@chakra-ui/react';
import { MdArrowForwardIos } from 'react-icons/md';
import { Outlet } from 'react-router';
import { CookieConsentModal } from '../components/CookieConsentModal';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { initializeAnalytics } from '../firebase';

export const MainLayout = () => {
    const [cookies, _setCookie] = useCookies(['egb-aq-cookie-consent']);

    const allowedCookies: string[] = [];
    if (cookies['egb-aq-cookie-consent']) {
        const [config, _date] = cookies['egb-aq-cookie-consent'].split('|');
        allowedCookies.push(...config.split('+'));
    }
    useEffect(() => {
        if (allowedCookies.includes('statistics')) {
            initializeAnalytics();
        }
    }, [allowedCookies])
    return (
        <>
            <CookieConsentModal />
            <Box display={'flex'} flexGrow={1} flexDirection={'column'}>
                <Box flexGrow={1} bgColor={'#1E3789'} width={'full'} color='white'>
                    <Container py={8} maxW={'lg'}>
                        <Outlet />
                    </Container>
                </Box>
                <Box bgColor={'#162765'} flexBasis={1} color='white'>
                    <Container py={8} maxW={'lg'}>
                        <List spacing={3} fontSize={'sm'} fontWeight={400}>
                            <ListItem>
                                <ListIcon as={MdArrowForwardIos} color='#FFC500' />
                                <Link href='https://www.aquarama.net/aviso-legal-aquarama/' target='_blank'>Aviso Legal</Link>
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdArrowForwardIos} color='#FFC500' />
                                <Link href='https://www.aquarama.net/politica-de-privacidad/' target='_blank'>Política de Privacidad</Link>
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdArrowForwardIos} color='#FFC500' />
                                <Link href='https://www.aquarama.net/condiciones-de-venta/' target='_blank'>Términos y Condiciones</Link>
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdArrowForwardIos} color='#FFC500' />
                                <Link href='/cookies' target='_blank'>Política de Cookies</Link>
                            </ListItem>
                        </List>
                    </Container>
                </Box>
            </Box>
        </>
    )
}