import {
    Image,
    Text,
    Center,
    Stack,
    Button,
    LinkBox,
    LinkOverlay,
} from '@chakra-ui/react';
import Logo from '../../assets/img/logo.png';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useParams } from 'react-router';
import { firebaseFunctions } from '../../firebase';
import { httpsCallable } from '@firebase/functions';
import { Link } from 'react-router-dom';

const checkoutCallable = httpsCallable<any, any>(firebaseFunctions, 'retryPayment');

export const PaymentKo = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { orderId } = useParams();

    const retry = async () => {
        setIsLoading(true);
        try {
            const response = await checkoutCallable({ orderId });
            if (response.data.paymentLink) {
                window.location.href = response.data.paymentLink;
            }
        } catch (e) {
            setIsLoading(false);
            console.error(e);
        }
    }

    return (
        <Stack spacing={12}>
            <Stack>
                <Center>
                    <LinkBox>
                        <LinkOverlay as={Link} to='/'>
                            <Image sx={{ width: 300 }} src={Logo} />
                        </LinkOverlay>
                    </LinkBox>
                </Center>
            </Stack>
            <Stack pt={8} spacing={4}>
                <Center>
                    <WarningTwoIcon boxSize={16} color='#FFC500' />
                </Center>
                <Text fontWeight={500} align={'center'} fontSize={'2xl'}>¡Error en el pago!</Text>
                <Text align={'justify'}>El pago no se ha podido procesar y no se te ha cobrado nada. ¡Inténtalo de nuevo!</Text>
            </Stack>
            <Stack spacing={4}>
                <Center px={8}>
                    <Button
                        onClick={retry}
                        isLoading={isLoading}
                        width='full'
                        size='xl'
                        borderRadius={'20px'}
                        variant='solid'
                        colorScheme='brand'
                        color={'#1E3689'}>
                        Reintentar
                    </Button>
                </Center>
            </Stack>
        </Stack>
    )
}