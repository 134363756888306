import { ChakraProvider } from '@chakra-ui/react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration
} from 'react-router-dom';
import { theme } from './theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NotFound } from './routes/misc/NotFound';
import { Error } from './routes/misc/Error'
import { CookiesProvider } from 'react-cookie';
import { Hammocks } from './routes/hammocks/Hammocks';
import { MainLayout } from './layout/MainLayout';
import { Checkout } from './routes/checkout/Checkout';
import { PaymentOk } from './routes/paymentOk/PaymentOk';
import { PaymentKo } from './routes/paymentKo/PaymentKo';
import { Cookies } from './routes/misc/Cookies';
import { Admin } from './routes/admin/Admin';


const Root = () => (
    <>
        <ScrollRestoration />
        <Outlet />
    </>
)

export const App = () => {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path='/' element={<Root />} errorElement={<Error />}>
                <Route path='hammocks' element={<MainLayout />}>
                    <Route index element={<Hammocks />} />
                    <Route path='checkout' element={<Checkout />} />
                    <Route path='payment/ok' element={<PaymentOk />} />
                    <Route path='payment/ko/:orderId' element={<PaymentKo />} />
                </Route>
                <Route path='admin' element={<MainLayout />}>
                    <Route index element={<Admin />} />
                </Route>
                <Route index element={<Navigate to='/hammocks' replace />} />
                <Route path='cookies' element={<MainLayout />}>
                    <Route index element={<Cookies />} />
                </Route>
                <Route path='not-found' element={<NotFound />} />
                <Route path='*' element={<Navigate to='/not-found' replace />} />
            </Route >
        )
    );
    return (
        <CookiesProvider>
            <HelmetProvider>
                <Helmet>
                    <title>Aquarama Hammocks</title>
                    <link rel='canonical' href='https://aquarama-hammock-reservation.web.app' />
                    <meta name='description'
                        content='Aquarama Hammocks' />
                </Helmet>
                <ChakraProvider theme={theme}>
                    <RouterProvider router={router} />
                </ChakraProvider>
            </HelmetProvider>
        </CookiesProvider>
    );
}