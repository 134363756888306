import {
    Image,
    Stack,
    Box,
    Center,
    HStack,
    Heading,
    Text,
    Button,
    ButtonGroup,
    IconButton
} from '@chakra-ui/react'

import Logo from '../../assets/img/logo.png'
import { HammockIcon } from '../../assets/icons/HammockIcon';
import { AddIcon, ArrowForwardIcon, MinusIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { firebaseFirestore } from '../../firebase';
import { AlertModal } from '../../components/AlertModal';
import { DateTime } from 'luxon';
export const Hammocks = () => {

    const [searchParams] = useSearchParams();

    const [showError, setShowError] = useState(false);
    const [hammockCount, setHammockCount] = useState(1);
    const [settings, setSettings] = useState<any>();

    const [
        miscSettingsSnapshot,
        isLoadingMiscSettingsSnapshot,
        hasMiscSettingsLoadingError
    ] = useDocumentData(doc(
        firebaseFirestore,
        'settings',
        'misc',
    ));

    const increaseHammocks = () => {
        setHammockCount(hammockCount + 1);
    }

    const decreaseHammocks = () => {
        if (hammockCount > 1) {
            setHammockCount(hammockCount - 1);
        }
    }

    const getPrice = () => {
        if (!settings) {
            return 0;
        }
        const now = DateTime.now().setZone('Europe/Madrid');
        const afternoonStart = now.set({
            hour: settings.afternoonStart,
            minute: 0,
            second: 0,
            millisecond: 0
        });

        const afternoonEnd = now.set({
            hour: settings.afternoonEnd,
            minute: 0,
            second: 0,
            millisecond: 0
        });
        if (now > afternoonStart && now <= afternoonEnd) {
            return settings.afternoonPrice;
        }
        return settings.price;
    }

    const getFormattedPrice = () => {
        return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
            (getPrice() ?? 0) * hammockCount
        );
    }

    useEffect(() => {
        const quantity = parseInt(searchParams.get('q') ?? '0', 10);
        if (quantity >= 1) {
            setHammockCount(quantity);
        }
    }, []);

    useEffect(() => {
        if (hasMiscSettingsLoadingError) {
            setShowError(true);
        } else if (miscSettingsSnapshot) {
            setSettings(miscSettingsSnapshot);
        }
    }, [
        miscSettingsSnapshot,
        hasMiscSettingsLoadingError
    ]);

    return (
        <>
            <AlertModal
                status={'error'}
                title={'Ha ocurrido un error'}
                message={'Actualmente no podemos obtener la información de precios. Vuelve a intentarlo más tarde.'}
                isOpen={showError}
                onClose={() => {
                    setShowError(false);
                }} />
            <AlertModal
                status={'error'}
                title={'Venta deshabilitada'}
                message={'Actualmente no podemos vender más tumbonas. Inténtalo más tarde.'}
                isOpen={settings?.canSell === false}
                 />
            <Stack spacing={12}>
                <Stack>
                    <Center>
                        <Image sx={{ width: 300 }} src={Logo} />
                    </Center>
                    <HStack spacing={'4'}>
                        <HammockIcon boxSize={30} />
                        <Heading lineHeight={1.5} fontWeight={500} fontSize={'md'}>¿Cuántas tumbonas desea pagar para asegurar su alquiler?</Heading>
                    </HStack>
                    <Text fontSize={'sm'} fontWeight={400}>
                        Indica la cantidad de tumbonas que desea alquilar.
                    </Text>
                    <Text fontSize={'sm'} fontWeight={400}>
                        Tenga en cuenta que sólo debe pagar por las tumbonas una vez situado en ellas.
                    </Text>
                    <Text fontSize={'sm'} fontWeight={400}>
                        Prohibido colocar las tumbonas en accesos, lugares de paso o jardines.
                    </Text>
                    <Text fontSize={'sm'} fontWeight={400}>
                        No se admiten devoluciones. Por ello, asegúrese bien del número de tumbonas alquiladas antes de confirmar el pago.
                    </Text>
                    <Text fontSize={'sm'} fontWeight={400}>
                        El pago de las tumbonas solo autoriza al uso de las mismas durante el día en el que se realizó.
                    </Text>
                </Stack>
                <Stack spacing={6}>
                    <Center>
                        <ButtonGroup size='lg' isAttached variant='solid' colorScheme='brand'>
                            <IconButton
                                isDisabled={hammockCount === 1}
                                aria-label='reducir'
                                icon={<MinusIcon color='#1E3689' />}
                                onClick={decreaseHammocks} />
                            <IconButton
                                aria-label='incrementar'
                                icon={<AddIcon color='#1E3689' />}
                                onClick={increaseHammocks} />
                        </ButtonGroup>
                    </Center>
                    <Box width={'full'} p={6} borderRadius={8} bgColor={'#1B2F78'}>
                        <HStack justify={'space-between'}>
                            <Stack>
                                <Text fontWeight={600} color='#FFC500'>Nº Tumbonas</Text>
                                <Text fontWeight={700} fontSize={'3xl'}>{hammockCount}</Text>
                            </Stack>
                            <Stack>
                                <Text textAlign={'right'} fontWeight={600} color='#FFC500'>Total</Text>
                                <Text textAlign={'right'} fontWeight={700} fontSize={'3xl'}>{getFormattedPrice()}</Text>
                            </Stack>
                        </HStack>
                    </Box>
                </Stack>
                <Center px={8}>
                    <Button
                        as={Link}
                        isLoading={isLoadingMiscSettingsSnapshot}
                        isDisabled={hasMiscSettingsLoadingError !== undefined}
                        to={`/hammocks/checkout?q=${hammockCount}`}
                        width='full'
                        size='xl'
                        borderRadius={'20px'}
                        variant='solid'
                        colorScheme='brand'
                        color={'#1E3689'}
                        rightIcon={<ArrowForwardIcon boxSize={6} />}>
                        Continuar
                    </Button>
                </Center>
            </Stack>
        </>
    )
}