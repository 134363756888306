import {
    Image,
    Text,
    Center,
    HStack,
    Heading,
    Stack,
    Input,
    InputGroup,
    InputLeftElement,
    FormControl,
    FormErrorMessage,
    Button,
    Checkbox,
    Link as ChakraLink,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react';
import { HammockIcon } from '../../assets/icons/HammockIcon';
import Logo from '../../assets/img/logo.png';
import { ArrowBackIcon, EmailIcon, WarningIcon } from '@chakra-ui/icons';
import { Link, useSearchParams } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { firebaseFunctions } from '../../firebase';
import { httpsCallable } from '@firebase/functions';
import { AlertModal } from '../../components/AlertModal';

const checkoutCallable = httpsCallable<any, any>(firebaseFunctions, 'checkout');

type CheckoutFormData = {
    email: string;
}

export const Checkout = () => {
    const [searchParams] = useSearchParams();
    const quantity = parseInt(searchParams.get('q') ?? '0', 10);
    const [conditionsAccepted, setConditionsAccepted] = useState(false);
    const [hasError, setHasError] = useState(false);

    if (Number.isNaN(quantity) || quantity < 1) {
        return <Navigate to='/hammocks' replace />
    }

    const send = (data: CheckoutFormData) => {
        return new Promise<void>((resolve) => {
            checkoutCallable({
                email: data.email,
                quantity
            }).then(response => {
                if (response.data.paymentLink) {
                    window.location.href = response.data.paymentLink;
                }
            }).catch(e => {
                setHasError(true);
                console.error(e);
                resolve();
            });
        });
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<CheckoutFormData>();

    return (
        <>
            <AlertModal
                status={'error'}
                title={'Ha ocurrido un error'}
                message={'Actualmente no podemos procesar el pedido. Vuelve a intentarlo más tarde.'}
                isOpen={hasError}
                onClose={() => {
                    setHasError(false);
                }} />
            <Stack spacing={12} as='form' noValidate onSubmit={handleSubmit(send)}>
                <Stack>
                    <Center>
                        <LinkBox>
                            <LinkOverlay as={Link} to='/'>
                                <Image sx={{ width: 300 }} src={Logo} />
                            </LinkOverlay>
                        </LinkBox>
                    </Center>
                    <HStack>
                        <HammockIcon boxSize={30} />
                        <Heading fontWeight={500} fontSize={'md'}>Confirmación</Heading>
                    </HStack>
                    <Text fontSize={'sm'} fontWeight={400}>
                        Introduce tu correo electrónico al que te enviaremos la confirmación de compra,
                        acepta los términos y condiciones, y pulsa en Pagar para ser dirigido a
                        la pasarela de pago seguro.
                    </Text>
                </Stack>
                <Stack spacing={4}>
                    <FormControl isInvalid={errors.email !== undefined}>
                        <InputGroup size='lg'>
                            <InputLeftElement pointerEvents='none'>
                                <EmailIcon color='#1E3789' boxSize={6} />
                            </InputLeftElement>
                            <Input
                                fontWeight={600}
                                color='#1E3789'
                                type='email'
                                placeholder='Correo electrónico'
                                {...register('email', {
                                    required: 'Campo obligatorio.',
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: 'Introduce un correo electrónico válido.'
                                    }
                                })}
                            />
                        </InputGroup>
                        {errors.email &&
                            <FormErrorMessage color='#ffe41b'>
                                {errors.email.message}
                            </FormErrorMessage>
                        }
                    </FormControl>
                    <Checkbox
                        colorScheme='brand'
                        onChange={() => {
                            setConditionsAccepted(!conditionsAccepted)
                        }}
                        size='lg'
                        iconColor='#1E3789'
                    >
                        Acepto los <ChakraLink
                            href='https://www.aquarama.net/condiciones-de-venta/'
                            target='_blank'
                            textDecoration={'underline'}>
                            términos y condiciones
                        </ChakraLink>.
                    </Checkbox>
                </Stack>
                <HStack color='white' spacing={4}>
                    <WarningIcon boxSize={25} />
                    <Text fontWeight={500} fontSize={'md'}>En caso de que el email sea erróneo no te llegará la confirmación.</Text>
                </HStack>
                <Stack spacing={4}>
                    <Center px={8}>
                        <Button
                            type='submit'
                            isDisabled={!conditionsAccepted}
                            isLoading={isSubmitting}
                            width='full'
                            size='xl'
                            borderRadius={'20px'}
                            variant='solid'
                            colorScheme='brand'
                            color={'#1E3689'}>
                            Pagar
                        </Button>
                    </Center>
                    <Center px={8}>
                        <Button
                            variant={'outline'}
                            backgroundColor={'transparent'}
                            colorScheme='brand'
                            as={Link}
                            to={`/hammocks?q=${quantity}`}
                            width='full'
                            size='xl'
                            borderRadius={'20px'}
                            color='white'
                            borderColor={'yellow'}
                            borderWidth={2}
                            leftIcon={<ArrowBackIcon boxSize={6} />}
                            _hover={{
                                borderWidth: 3
                            }}
                            _active={{
                                borderWidth: 3
                            }}>
                            Cancelar
                        </Button>
                    </Center>
                </Stack>
            </Stack>
        </>
    )
}