import { initializeApp } from '@firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';
import { connectFirestoreEmulator, initializeFirestore } from '@firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from '@firebase/functions';
import ReactGA from 'react-ga4';

const firebaseConfig = {
    apiKey: 'AIzaSyBHeLyMDRcOy4h6-fHdA15S5xL4DLgAKZc',
    authDomain: 'aquarama-hammock-reservation.firebaseapp.com',
    projectId: 'aquarama-hammock-reservation',
    storageBucket: 'aquarama-hammock-reservation.appspot.com',
    messagingSenderId: '740491503339',
    appId: '1:740491503339:web:a453b3e527117b1b4540ae',
    measurementId: 'G-55BEJYGFC7'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

if (process.env.APP_CHECK_DEBUG_TOKEN) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN;
}

export const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6LeNM8ElAAAAAC-k552uCydKE17iZ12-53fY1VZ5'),
    isTokenAutoRefreshEnabled: true
});

const firestore = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
const functions = getFunctions(firebaseApp, 'europe-west3');

const emulatorHost = process.env.EMULATOR_HOST ?? 'localhost';

if (process.env.USE_EMULATORS === 'true') {
    connectFirestoreEmulator(firestore, emulatorHost, 8080);
    connectFunctionsEmulator(functions, emulatorHost, 5001);
}

export const initializeAnalytics = () => {
    if (!ReactGA.isInitialized) {
        ReactGA.initialize([{
            trackingId: firebaseConfig.measurementId,
        }]);
    }
}
export const logAnalyticsEvent = (
    eventName: string,
    eventParams?: { [key: string]: any; },
) => {
    if (ReactGA.isInitialized) {
        ReactGA.event(eventName, eventParams);
    }
}
export const firebaseFirestore = firestore;
export const firebaseFunctions = functions;