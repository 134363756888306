import { Icon } from '@chakra-ui/react';

export const HammockIcon = (props: any) => (
    <Icon viewBox='0 0 30 22' {...props}>
        <rect y="16.6447" width="30" height="1.69412" rx="0.847059" fill="currentColor" />
        <path d="M6.44443 22C5.95351 22 5.55554 21.602 5.55554 21.1111L5.55554 19.0588L7.33332 19.0588L7.33332 21.1111C7.33332 21.602 6.93535 22 6.44443 22Z" fill="currentColor" />
        <path d="M25.3333 22C24.8424 22 24.4445 21.602 24.4445 21.1111L24.4445 19.0588L26.2222 19.0588L26.2222 21.1111C26.2222 21.602 25.8243 22 25.3333 22Z" fill="currentColor" />
        <rect width="12.6786" height="1.71877" rx="0.859385" transform="matrix(0.85426 0.519847 -0.556685 0.830723 2.33899 10.3137)" fill="currentColor" />
        <rect width="4.23685" height="1.09827" transform="matrix(-0.518177 0.855273 -0.876149 -0.48204 7.10608 13.8947)" fill="currentColor" />
        <rect x="14.4445" y="15.8823" width="11.6471" height="1.11111" transform="rotate(-90 14.4445 15.8823)" fill="currentColor" />
        <path d="M4.44446 3.73574C4.44446 3.42089 4.62016 3.12922 4.8988 2.98261C12.5769 -1.0573 17.1552 -0.933064 25.0865 2.99011C25.3728 3.13172 25.5556 3.42598 25.5556 3.74539C25.5556 4.21283 25.1766 4.59177 24.7092 4.59177H5.30049C4.82772 4.59177 4.44446 4.20852 4.44446 3.73574Z" fill="currentColor" />
    </Icon>
)