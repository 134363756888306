const baseStyle = {
  content: {
    borderWidth: '1px',
    boxShadow: 'lg',
    borderRadius: 'lg',
    background: 'bg-surface',
    overflow: 'hidden',
  },
}

export default {
  baseStyle,
}
